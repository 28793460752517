.navbar {
    @apply bg-black !important;
    @apply h-[75px] !important;
}
.navbar header {
    @apply max-w-[1300px] px-0;
}
.logo {
    @apply flex flex-shrink-0 items-center;
}
.logoImage {
    @apply h-auto max-w-[100px];
}
.navbarOpen[data-menu-open='true'] {
    @apply border-b border-solid;
}
.cat_button {
    @apply bg-white w-[120px] h-[35px] text-black capitalize font-normal border-black text-sm px-0 !important;
    @apply hover:bg-white hover:opacity-100 !important;
    @apply hover:text-blue-600 !important;
}
.mobilenav {
    @apply px-8 pt-6 list-none bg-gray-100 !important;
}
.emlLi {
    @apply h-[75px] !important;
}
.emlA {
    @apply capitalize text-gray-50 !important;
    @apply hover:text-blue-600 !important;
}
.button_item {
    @apply capitalize text-base font-medium p-0 bg-transparent data-[hover=true]:bg-transparent text-gray-50 !important;
}
.emlB {
    @apply capitalize text-black !important;
    @apply hover:text-blue-600 !important;
}
